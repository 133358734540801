.container{
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    margin-top: 0rem;
}
.footer_item{
    text-decoration: none;
}
.def_footer_font{
    font-size: .875rem;
}
.footer_list{
    text-decoration: none;
    list-style: none;
    padding: 0.5rem 0;
}
.dis{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

}

.footerg{
    margin-right: 24px;
    display: flex;
    flex-direction: column;
}
.width100{
    width: 100%;
    padding: 0 1rem;
    max-width: 1182px;
}
.flex_container{
    display: flex;
    justify-content: center;
}
.logo{
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(2 * 24px);
    transition: background-color .2s
}

.copyright{
    padding: 2rem;
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: center;
}

@media screen and (max-width: 820px) {
    .logo{
        margin: 0.1rem 0 2rem 0;
    }
    .container{
        border-top: 0px solid #eaeaea;
    }
    .container{
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex-direction: column;
    }
    .seperate{
        margin: 1rem 0 0.5rem 0;
    }
    .footerg{
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: left;
        width: 100%;
        font-size: 1.3rem;
        height: calc(2 * 24px);
        border-bottom: 1px solid #eaeaea;
        transition: background-color .2s

    }
}


