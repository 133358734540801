.layout:nth-child(even){
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 5rem;
    
  }

  .entire{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .layout:nth-child(odd){
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    max-width: 1182px;
  }
  
  .sect{
    width: 100%;
    max-width: 800px;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .subjects{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media screen and (max-width: 680px) {
    .sect{
      width: 100%;
      max-width: 100%;
      font-size: 40px;
      text-align: center;
      padding: 0.5rem 2rem 0rem;
      
    }
    
    .layout{
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }

    .subjects{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    
}

  @media screen and (max-width: 950px) {
    .mobileremove{
      display: none;
    }

    .logo{
      display: flex;
    }
  
  }
  
  .subjectbox{
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    min-height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ebe6e6;
    margin: 0.5rem;
  }
  
  .subjectbox:hover{
    box-shadow: 0 0 35px -2px rgb(0 0 0 / 20%);
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  
  .subjectcon{
    align-items: center;
      border-radius: 8px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      max-width: 400px;
      padding: 20px;
      text-align: center;
      transition: box-shadow .3s;
      font-size: 2rem;
      min-height: 6rem;
  }
  
.reducedsize{
  margin: 0;
  margin-bottom: 0.5rem;
}

.flex_container{
    display: flex;
    justify-content: center;
}

.flex_container_gray{
    display: flex;
    justify-content: center;
    background-color: #f8f7f9;
}


.layoutc{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.5rem;
}