.header{
    background-color: #f8f7f9;
    min-width: 100%;
    width: 100%;
    top:0;
    background-color: #f8f7f9;
    z-index: 1;
  }
  
  .navbar {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
    .logo{
      text-align: start;
    }
  
    .side {
      display: grid;
      grid-template-columns: repeat(7, auto);
      grid-gap: 2.5rem;
      width: 80vw;
      text-align: center;
      justify-content: end;
    }
  
    .noside{
      display: grid;
      grid-template-columns: repeat(6, auto);
      grid-gap: 2.5rem;
      width: 80vw;
      flex-shrink: 0.5;
      text-align: center;
      justify-content: right;
    }
  
    .sidetext{
      text-align: center;
    }
    
    .togglebtn{
      display: none;
    }
  
    .show{
      display: none;
    }
  
    @media screen and (max-width: 950px) {
      .logo{
          display: flex;
        }
        .mainmobile{
          display: none;
        }
      
        .togglebtn{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          pointer-events: none;
          cursor: pointer;
          height: 20px;
        }
      
        .togglebtn::after{
          content: "";
          display: block;
          height: 1px;
          width: 22px;
          background-color: black;
          transition: transform .15s ease;
      
        }
        .togglebtn::before{
          content: "";
          display: block;
          height: 1px;
          width: 22px;
          background-color: black;
          transition: transform .15s ease;
      
        }
        .rotate::after{
          transform: translateY(-4px) rotate(0deg);
        }
        .rotate::before{
          transform: translateY(4px) rotate(0deg);
        }
      
        .rerotate::after{
          transform: translateY(0) rotate(-45deg);
        }
        .rerotate::before{
          transform: translateY(0) rotate(45deg);
        }
        .side{
          display: flex;
          flex-direction: column;
          width: 100%;
          position: absolute;
          top: 80px;
          background-color: white;
        }
      
        .noside{
          display: none;
        }
      
        .sidetext{
          width: 100%;
          display: block;
        }
      
        .logo{
          text-align: center;
          width: 100%;
        }
      
        .main {
          padding: 1rem;
        }  
        
        .show{
          display: block;
          width: 100%;
        }
    }
  
    .hide{
      display: none;
    }
  
    .container{
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      padding: 1rem 2rem;
    }
  
    .mobileheader{
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 1rem;
      height: calc(2 * 24px);
      border-bottom: 1px solid #eaeaea;
      transition: background-color .2s ease-in-out;
    }
    .white{
      color: white;
    }
    .white:hover{
      color:#0070f3
    }
    .width100{
      width: 100%;
    }
    .mobileheader:hover{
      background-color: #f2f0f3;
    }
    .authbtn1{
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      position: relative;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -moz-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: 0;
      text-align: center;
      text-decoration: none;
      line-height: 38px;
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--font-sans);
      min-width: 100%;
      height: 40px;
      padding: 0 25px 0 25px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      font-size: 0.875rem;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0;
      color: white;
      background-color: #0070f3;
      border: 1px solid #0070f3;
      -webkit-transition: all.2s ease;
      -moz-transition: all.2s ease;
      -o-transition: all.2s ease;
      transition: all.2s ease;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: 1rem;
    }
  
    .authbtn1:hover{
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      position: relative;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -moz-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: 0;
      text-align: center;
      text-decoration: none;
      line-height: 38px;
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--font-sans);
      min-width: 100%;
      height: 40px;
      padding: 0 25px 0 25px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      font-size: 0.875rem;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0;
      color: var(--button-fg);
      background-color: #f8f7f9;
      border: 1px solid #0070f3;
      color: #0070f3;
      -webkit-transition: all.2s ease;
      -moz-transition: all.2s ease;
      -o-transition: all.2s ease;
      transition: all.2s ease;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: 1rem;
    }
  
    .authbtn2{
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
      position: relative;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -moz-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      z-index: 0;
      text-align: center;
      text-decoration: none;
      line-height: 38px;
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--font-sans);
      min-width: 100%;
      height: 40px;
      padding: 0 25px 0 25px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      font-size: 0.875rem;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin: 0;
      color: gray;
      border: 1px solid gray;
      -webkit-transition: all.2s ease;
      -moz-transition: all.2s ease;
      -o-transition: all.2s ease;
      transition: all.2s ease;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      margin-bottom: 1rem;
    }
    
  
    .authbtn2:hover{
      color:black;
      border: 1px solid black;
      margin-bottom: 1rem;
    }

    .removeHL{
        text-decoration: none;
    }