/* HEADER */

.header{
    background-color: #f8f7f9;
    min-width: 100%;
    width: 100%;
    top:0;
    background-color: #f8f7f9;
    z-index: 1;
  }
  
  .navbar {
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fixed{
    position: fixed;
    background-color: #f8f7f9;
    min-width: 100%;
    width: 100%;
    top:0;
    background-color: #f8f7f9;
    z-index: 1;
    overflow-y: scroll;
  }
  
  .fixed1{
    position: fixed;
    background-color: #f8f7f9;
    min-width: 100%;
    width: 100%;
    top:0;
    background-color: #f8f7f9;
    z-index: 1;
    overflow: hidden;
  }
  
  .logo{
    text-align: start;
  }
  
  .side {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 2.5rem;
    width: 80vw;
    text-align: center;
    justify-content: end;
  }
  
  .mobile{
    display: none;
  }
  
  .noside{
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 2.5rem;
    width: 80vw;
    flex-shrink: 0.5;
    text-align: center;
    justify-content: end;
  }
  
  .sidetext{
    text-align: center;
  
  }
  
  .togglebtn{
    display: none;
  }
  
  @media screen and (max-width: 950px) {
    
    .logo{
        display: flex;
      }
    
      .togglebtn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        pointer-events: none;
        cursor: pointer;
        height: 20px;
        -webkit-tap-highlight-color: transparent;
      }
    
      .togglebtn::after{
        content: "";
        display: block;
        height: 1px;
        width: 22px;
        background-color: black;
        transition: transform .15s ease;
  
      }
      .togglebtn::before{
        content: "";
        display: block;
        height: 1px;
        width: 22px;
        background-color: black;
        transition: transform .15s ease;
  
    
      }
      .rotate::after{
        transform: translateY(-4px) rotate(0deg);
  
      }
      .rotate::before{
        transform: translateY(4px) rotate(0deg);
  
      }
    
      .rerotate::after{
        transform: translateY(0) rotate(-45deg);
      }
      .rerotate::before{
        transform: translateY(0) rotate(45deg);
      }
      .side{
        display: none;
        flex-direction: column;
        width:90%;
        position: absolute;
        top: 58px;
        background-color: white;
      }
  
      .mobile{
        display: flex;
      }
    
      .noside{
        display: none;
      }
    
      .sidetext{
        width: 100%;
        display: block;
        border-bottom: black 1px solid;
        padding: 0 0 4rem 0;
      }
    
      .logo{
        text-align: center;
        width: 100%;
      }
    
      .main {
        padding: 1rem;
      }    
  }
  
  /* Body */
  
  .entire{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .remove{
  
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
  
  }
  
  .layout:nth-child(even){
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 5rem;
    
  }
  
  .layout:nth-child(odd){
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    max-width: 1182px;
  }
  
  .sect{
    width: 100%;
    max-width: 800px;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .btncon{
    display: flex;
    justify-content: left;
  }
  
  .btntest{
    text-align: center;
    border: 0;
    background-color: #4671c6;
    color: #ffffff;
    font-size: 0.9rem;
    font-weight: 600;
    position: relative;
    outline: none;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 3em;
    width: 9em;
    margin: 0 2rem 0 0;
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  
  .btntest:hover{
    box-shadow: 0 0 35px -3px rgb(0 0 0 / 40%);
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  
  .sectc{
    font-size: 14px;
    margin: 1rem 0 0.5rem 0;
    text-align: left;
    width: 100%;
  }
  
  .subjects{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  .righttxt{
    text-align: right;
    margin: 0 0 2rem;
    width: 100%;
  }
  
  .righttxt_l{
    font-size: 14px;
    text-align: right;
    width: 100%;
  }
  
  .bgr{
    background-color: #f8f7f9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 0rem;
  }
  
  .imgcorrection1{
    padding-top: 3.8rem;
    padding-right: 2rem;
  }
  
  .headingleft{
    text-align: left;
    width: 100%;
    margin: 0;
  }
  
  @media screen and (min-width: 1110px) {
    .bgr{
      padding-right: 10rem;
    }
  }
  
  @media screen and (min-width: 1050px) {
    .bgr{
      padding-right: 6rem;
    }
  }
  
  
  @media screen and (max-width: 730px) {
    .sect{
      width: 100%;
      max-width: 100%;
      font-size: 40px;
      text-align: center;
      
    }
    
    .layout{
      display: flex;
      flex-direction: column;
      max-width: 100%;
    }
    .btncon{
      display: flex;
      justify-content: center;
    }
  
    .btntest{
      margin: 0 0.5rem 0 0.5rem;
    }
  
    .sectc{
      text-align: center;
    }
  
    .subjects{
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    
    .righttxt{
      text-align: center;
    }
    
    .righttxt_l{
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  
    .bgr{
      padding-right: 0rem;
    }
  
    .imgcorrection1{
      padding-top: 3.8rem;
      padding-right: 0rem;
    }
  
    .headingleft{
      text-align: center;
    }
  }
  .mobileremove{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mainmobile{
    display: none;
  }
  @media screen and (max-width: 950px) {
  
    .bgr{
      padding-right: 0rem;
    }
  
    .height100{
      height: 100%;
    }
  
  }
  
  @media screen and (max-width: 950px) {
    .mobileremove{
      display: none;
    }
  }
  
  .subjectbox{
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    min-height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .subjectbox:hover{
    box-shadow: 0 0 35px -2px rgb(0 0 0 / 20%);
    transition: 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  
  .subjectcon{
    align-items: center;
      border-radius: 8px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      max-width: 400px;
      padding: 20px;
      text-align: center;
      transition: box-shadow .3s;
      font-size: 2rem;
      min-height: 6rem;
  }
  
  .resizer{
    width: 120;
    height: 120;
  }
  
  .heading1{
    margin-bottom: 0;
  }
  
  .heading2{
    text-align: center;
  }
  
  .imgcorrection{
    padding-top: 3.8rem;
  }
  
  .bgr21d{
    background-color: #f8f7f9;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex_container{
    display: flex;
    justify-content: center;
  }